import { Form } from '../../components';

import './about.scss';

const About = ({ data }) => (
  <section className="about" id="about">
    <div className="container">
      <div className="about__inner">
        <div className="about__content">
          {data.map((el, index) => (
            <div className="about__content--card" key={index}>
              <header>
                <img src={el.icon} alt={el.title} />

                <div className="title">{el.title}</div>
              </header>

              <p>{el.description}</p>
            </div>
          ))}
        </div>

        <div className="about__form">
          <div className="form-wrapper">
            <div className="title">Let’s connect and get your leads!</div>
            <Form />
          </div>

          <div className="awards">
            <div>
              <img src="../assets/award-1.svg" alt="Award 1" />
            </div>

            <div>
              <img src="../assets/award-2.svg" alt="Award 2" />
            </div>
            <div>
              <img src="../assets/award-3.svg" alt="Award 3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default About;
