import { Fragment, useEffect, useState } from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { Spinner } from '../../components';

import './caseStudies.scss';

const home_services = 18;
const moving = 19;
const spa = 22;

const decodeHtml = (html) => {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

const CaseStudies = () => {
  const location = window.location.pathname.split('/')[1];

  const [cases, setCases] = useState([]);
  const { isLoading, data, error } = useFetch('https://toporin.com/wp-json/wp/v2/case?per_page=100');

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  useEffect(() => {
    if (!isLoading) {
      let arr = [];

      switch (location) {
        case 'appliance-repair':
          arr = data?.filter((el) => el.cases_cat.includes(home_services));
          break;
        case 'moving':
          arr = data?.filter((el) => el.cases_cat.includes(moving));
          break;
        case 'electrical':
          arr = data?.filter((el) => el.cases_cat.includes(home_services));
          break;
        case 'hvac':
          arr = data?.filter((el) => el.cases_cat.includes(home_services));
          break;
        case 'spa':
          arr = data?.filter((el) => el.cases_cat.includes(spa));
          break;
        default:
          break;
      }

      setCases(arr);
    }
  }, [isLoading, data, location]);

  return (
    <section className="case-studies" id="services">
      <div className="container">
        <div className="case-studies__inner">
          {cases.length < 1 ? (
            <Spinner />
          ) : (
            <Fragment>
              <h2>Case studies</h2>
              <div className="case-studies__list">
                <Link to={`https://toporin.com/work/${cases[0].slug}`} target="_blank" rel="noReferrer">
                  <img src={cases[0].acf.archive_image} alt={cases[0].title.rendered} />

                  <div className="title">{cases[0].title.rendered}</div>
                </Link>

                <div>
                  {cases.slice(1, 5).map((el) => (
                    <Link to={`https://toporin.com/work/${el.slug}`} target="_blank" rel="noReferrer" key={el.id}>
                      <img src={el.acf.archive_image} alt={el.title.rendered} />
                      <div className="title">{decodeHtml(el.title.rendered)}</div>
                    </Link>
                  ))}
                </div>
              </div>

              <Link className="btn transparent" to="https://toporin.com/work/" target="_blank" rel="noReferrer">
                See All Case Studies
              </Link>
            </Fragment>
          )}
        </div>
      </div>
    </section>
  );
};

export default CaseStudies;
