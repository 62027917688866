import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import './hero.scss';

const Hero = ({ title, image, promo }) => (
  <section className="hero" id="hero">
    <div className="container">
      <div className="hero__inner">
        <div className="hero__content">
          <h1>
            Rated #1
            <br />
            {title}
            <br />
            Marketing Solutions
          </h1>

          <div className="mobile-image" style={{ backgroundImage: `url(${image})` }} />

          <div className="links">
            <Link to="https://toporin.com/contact/" target="_blank" rel="noReferrer" className="btn accent">
              Talk To Us
            </Link>

            <span>
              or call us <Link to="tel:(858) 630-9920">(858) 630-9920</Link>
            </span>
          </div>
        </div>

        <div className="hero__image" style={{ backgroundImage: `url(${image})` }} />
      </div>
    </div>
    <div className="hero__ticker">
      <div className="container">
        <div className="items-wrap">
          <div className="items marquee">
            {promo.map((el, index) => (
              <Fragment key={index}>
                <div className="item">{el.title}</div>
                <div className="item">|</div>
              </Fragment>
            ))}
          </div>
          <div aria-hidden="true" className="items marquee">
            {promo.map((el, index) => (
              <Fragment key={index}>
                <div className="item">{el.title}</div>
                <div className="item">|</div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
