import Slider from 'react-slick';

import './reviews.scss';

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: false,
  centerMode: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Reviews = ({ data }) => (
  <section className="reviews">
    <div className="container">
      <div className="reviews__inner">
        <h2>Reviews</h2>

        <Slider className="reviews__list" {...sliderSettings}>
          {data.map((el, index) => (
            <div className="reviews__list--slide" key={index}>
              <div className="photo">
                <img src={el.photo} alt={el.name} />
              </div>

              <div className="text">
                <div className="name">{el.name}</div>
                <div className="company">{el.company}</div>

                <p>{el.text}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  </section>
);

export default Reviews;
