import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import useFetch from 'react-fetch-hook';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Spinner } from './components';

import { PageTemplate, Page404 } from './pages';

export const ADMIN_URL = 'https://industry-admin.toporin.com/wp-json/wp/v2';

const App = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/pages`);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/appliance-repair" />} />

      {data?.map((page) => (
        <Route
          key={page.id}
          path={`/${page.slug}`}
          element={
            <PageTemplate
              data={language === 'en' ? page.acf.english : page.acf.ukrainian}
              seo={page.yoast_head_json}
              language={language}
              setLanguage={setLanguage}
            />
          }
        />
      ))}

      <Route
        path="/404"
        element={
          <Page404
            // data={language === 'en' ? page.acf.english : page.acf.ukrainian}
            // seo={page.yoast_head_json}
            language={language}
            setLanguage={setLanguage}
          />
        }
      />

      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};

export default App;
