import './partners.scss';

const Partners = () => (
  <section className="partners">
    <div className="container">
      <div className="partners__inner">
        <h2>
          The trusted growth partner for
          <br />
          your business ideas!
        </h2>

        <div className="partners__list">
          <img src="../../assets/partners/1.svg" alt="Partner" />
          <img src="../../assets/partners/2.svg" alt="Partner" />
          <img src="../../assets/partners/3.svg" alt="Partner" />
          <img src="../../assets/partners/4.svg" alt="Partner" />
          <img src="../../assets/partners/5.svg" alt="Partner" />
          <img src="../../assets/partners/6.svg" alt="Partner" />
        </div>
      </div>
    </div>
  </section>
);

export default Partners;
