import { Link } from 'react-router-dom';

import './cta.scss';

const CallToAction = () => (
  <section className="cta">
    <div className="container">
      <div className="cta__inner">
        <h2>
          Ready to kick off your
          <br />
          growth journey?
        </h2>

        <Link to="https://toporin.com/contact/" target="_blank" rel="noReferrer" className="btn black">
          Talk To Us
        </Link>
      </div>
    </div>
  </section>
);

export default CallToAction;
