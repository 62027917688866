import { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';

import './header.scss';

const Header = () => {
  const [shadow, setShadow] = useState(false);
  const [open, setOpen] = useState(false);

  const menuToggle = () => setOpen(!open);

  const handleScroll = () => setShadow(window.scrollY > 0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`page-header ${shadow && 'shadow'}`}>
      <div className="container">
        <div className="page-header__inner">
          <Link to="/" className="page-header__logo">
            <img src="../assets/logo.svg" alt="Toporin Studio" />
          </Link>

          <Burger onClick={menuToggle} />

          <nav className={open ? 'open' : null}>
            <CloseButton onClick={menuToggle} />

            <ul className="nav-menu">
              <li>
                <ScrollLink activeClass="active" to="hero" spy={true} smooth={true} offset={-200} duration={500}>
                  Home
                </ScrollLink>
              </li>
              <li>
                <ScrollLink activeClass="active" to="about" spy={true} smooth={true} offset={-70} duration={500}>
                  Services
                </ScrollLink>
              </li>
              <li>
                <ScrollLink activeClass="active" to="services" spy={true} smooth={true} offset={100} duration={500}>
                  Work
                </ScrollLink>
              </li>
              <li>
                <Link to="https://toporin.com/contact/" target="_blank" rel="noReferrer">
                  Contact
                </Link>
              </li>
            </ul>

            <Link className="btn transparent" to="https://toporin.com/work/" target="_blank" rel="noReferrer">
              All Case Studies
            </Link>
          </nav>

          <div className="buttons">
            <Link className="btn transparent" to="https://toporin.com/work/" target="_blank" rel="noReferrer">
              All Case Studies
            </Link>

            {/* <div className="btn accent" onClick={() => setLanguage(language === 'en' ? 'ua' : 'en')}>
              {language === 'en' ? 'UA' : 'EN'}
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

const Burger = ({ onClick }) => (
  <div className="burger" {...{ onClick }}>
    <span />
    <span />
    <span />
  </div>
);

const CloseButton = ({ onClick }) => <div className="close" {...{ onClick }} />;
