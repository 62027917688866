import { Link } from 'react-router-dom';

import './mobileButtons.scss';

const MobileButtons = ({ isVisible, phone }) => (
  <div className={`mobile-cta-buttons ${isVisible ? 'on' : 'off'}`}>
    <Link to={`tel:${phone}`}>{phone}</Link>
    <Link to="https://toporin.com/contact/" target="_blank" rel="noReferrer">
      Talk To Us
    </Link>
  </div>
);

export default MobileButtons;
