import axios from 'axios';
import { toast } from 'react-toastify';

import './form.scss';

const MAIL_URL = `https://industry-admin.toporin.com/wp-json/contact-form-7/v1/contact-forms/5/feedback`;

const Form = () => {
  const submit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const response = await axios.post(MAIL_URL, data);

      if (response.status === 200) {
        toast.success(response.data.message);
        event.target.reset();
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };

  return (
    <form className="feedback-form" onSubmit={submit}>
      <input required type="text" name="customer_name" placeholder="Name *" />

      <input required type="email" name="customer_email" placeholder="Email *" />

      <input required type="tel" name="customer_phone" placeholder="Phone *" />

      <input required type="text" name="company_name" placeholder="Company name *" />

      <select name="service">
        <option hidden value="">
          What are you looking for? *
        </option>
        <option value="Web Design & Development">Web Design & Development</option>
        <option value="Digital Marketing">Digital Marketing</option>
        <option value="LeadGen System">LeadGen System</option>
        <option value="Custom Software Development">Custom Software Development</option>
      </select>

      <input type="submit" value="Submit" className="btn accent" />

      <fieldset>
        <div className="data-protection">
          <div>
            <svg
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="LockIcon"
              style={{
                width: 20,
                height: 20,
                marginRight: 5,
                fill: '#070707',
              }}
            >
              <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
            </svg>
          </div>
          <p>Data protected and privacy</p>
        </div>

        <span className="notice">
          * When you request a service, you agree to receive SMS and email notifications regarding the service request.
        </span>
      </fieldset>
    </form>
  );
};

export default Form;
