import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import './footer.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="page-footer">
      <div className="container">
        <div className="page-footer__inner">
          <div className="page-footer__heading">
            <img src="../../assets/footer.svg" alt="We're headquartered in San Diego" />
          </div>

          <div className="page-footer__grid">
            <div>
              <span>Contacts</span>

              <p>
                835 5th Ave,
                <br />
                San Diego, CA 92101
                <br />
                <Link to={`tel:(858) 630-9920`}>(858) 630-9920</Link>
              </p>
            </div>

            <div>
              <span>Navigation</span>

              <ul>
                <li>
                  <Link to="https://toporin.com/company/about/" target="_blank" rel="noReferrer">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="https://toporin.com/services/" target="_blank" rel="noReferrer">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="https://toporin.com/career/" target="_blank" rel="noReferrer">
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to="https://toporin.com/company/blog/" target="_blank" rel="noReferrer">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to="https://toporin.com/contact/" target="_blank" rel="noReferrer">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <span>Info</span>

              <ul>
                <li>
                  <Link to="https://toporin.com/terms-of-use/" target="_blank" rel="noReferrer">
                    Terms of use
                  </Link>
                </li>
                <li>
                  <Link to="https://toporin.com/privacy-policy/" target="_blank" rel="noReferrer">
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="page-footer__underline">
          <ScrollLink to="hero" spy={true} smooth={true} offset={-200} duration={500}>
            <img src="../../assets/logo_footer.svg" alt="Toporin Studio" />
          </ScrollLink>

          <p>All right reserved © {currentYear}.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
