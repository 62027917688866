import ReactPlayer from 'react-player';

import './video.scss';

const VideoBlock = () => (
  <section className="video-block" id="work">
    <div className="container">
      <div className="video-block__inner">
        <h2>Supercharge Your Marketing!</h2>

        <p>
          With a deep understanding of the unique challenges and opportunities in the industry, we specialize in
          providing tailored marketing solutions that drive growth and boost your brand's visibility.
        </p>

        <div className="video-wrapper">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=XS568EIykMI&feature=youtu.be"
            playIcon={<div className="play-icon" />}
            light={<img src="../../assets/video-bg-new.jpeg" alt="Thumbnail" />}
            playing
            config={{
              youtube: {
                playerVars: { showinfo: false },
              },
            }}
          />
        </div>
      </div>
    </div>
  </section>
);

export default VideoBlock;
