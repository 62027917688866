import { Layout, Seo } from '../components';

import { Hero, About, VideoBlock, CaseStudies, Reviews, Partners, CallToAction } from '../sections';

const PageTemplate = ({ data, language, setLanguage, seo }) => (
  <Layout pageTitle={data.title} language={language} setLanguage={setLanguage}>
    <Seo data={seo} />
    <Hero title={data.title} image={data.image} promo={data.promo} />
    <About data={data.about} />
    <VideoBlock />
    <CaseStudies />
    <Reviews data={data?.reviews} />
    <Partners />
    <CallToAction />
  </Layout>
);

export default PageTemplate;
