import { Layout } from '../components';

import { Error404 } from '../sections';

const Page404 = ({ language, setLanguage }) => (
  <Layout pageTitle="Page Not Found" language={language} setLanguage={setLanguage}>
    <Error404 />
  </Layout>
);

export default Page404;
